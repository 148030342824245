/* Highlight selected team with different colors based on result and bold font */

/* Default Yellow (for no result) */
.selected-team {
    background-color: rgba(255, 235, 59, 0.6); /* Subdued Yellow */
    color: #333;
    font-weight: bold; /* Make selected team bold */
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Green for Win (W) */
.selected-team-win {
    background-color: rgba(76, 175, 80, 0.3); /* Subdued Green */
    color: #333;
    font-weight: bold; /* Make selected team bold */
}

/* Red for Loss (L) */
.selected-team-loss {
    background-color: rgba(244, 67, 54, 0.3); /* Subdued Red */
    color: #333;
    font-weight: bold; /* Make selected team bold */
}

/* Gray for Tie (T) */
.selected-team-tie {
    background-color: rgba(158, 158, 158, 0.3); /* Subdued Gray */
    color: #333;
    font-weight: bold; /* Make selected team bold */
}

/* Bold team name without highlight */
.selected-team-name {
    font-weight: bold; /* Make team name bold */
}

/* Table Styling */
.submitpicks {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    margin-top: 140px;
}

.selection-status {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 10px 0;
    font-size: 1.2em;
    color: #333;
    z-index: 1000;
}

.status-and-clock {
    position: fixed;
    top: 40px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    z-index: 1000;
    border-bottom: 1px solid #ddd;
}

.clock {
    font-size: 1em;
    color: #333;
    margin-right: 20px;
}

.submit-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.submit-button:hover {
    background-color: #45a049;
}

.button-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
}  

h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

h3.date-heading {
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
}

.game-table {
    width: 100%;
    max-width: 800px;
    min-width: 600px;
    margin: 0 auto;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.game-table th, .game-table td {
    padding: 10px;
    border: 1px solid #ddd;
    box-sizing: border-box;
}

.checkbox-align {
    text-align: center;
    width: 40px;
}

.left-align {
    text-align: left;
}

.center-align {
    text-align: center;
}

.right-align {
    text-align: right;
}

/* --- Sidebar & Main Layout --- */
.app-container {
    display: flex;
    height: 100vh;
}

/* Sidebar styling */
.sidebar {
    width: 250px;
    background-color: #333;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sidebar h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 15px;
}

.sidebar ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.1em;
}

.sidebar ul li a:hover {
    text-decoration: underline;
}

/* Main content area styling */
.main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #f5f5f5;
}

/* Sign Out Button Styling */
.signout-button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.1em;
    text-align: left; /* Align text to the left like the links */
    padding: 0; /* Remove default button padding */
    margin: 0; /* Remove default margin */
    display: block; /* Make it behave like a block element like <li> */
    width: 100%; /* Ensure full width, like <a> */
}

.signout-button:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .app-container {
        flex-direction: column;
    }
    
    .sidebar {
        width: 100%;
        text-align: center;
    }
}
